import logo from './assets/forjoypro_logo1.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Estamos chegando!</p>
        <p>We are coming!</p>
        <img src={logo} className="App-logo" alt="logo" />
        <p>Contato / Contact:</p>
        <p>41 99544-0770 / rodrigo@forjoypro.com.br</p>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn Reacts
        </a> */}
      </header>
    </div>
  );
}

export default App;
